import React from "react";

export default function Developer() {
  return (
    <>
      <div className="container-fluid">
        <div id="about" className="row about-section">
          <div className="col-lg-4 about-card">
            <div>
              <h3 className="font-weight-light">Who am I ?</h3>
              <span className="line mb-5"></span>
              <h5 className="mb-3">
                A Web Developer Located In Melbourne Australia.
              </h5>
              <p className="mt-20">
                A AWS Certified Developer (Cleared <strong>AWS Cloud Practitioner</strong> May 2024 ).
                In industry from over 8 years, Worked with different clients
                with different needs. A advanced developer who delivers the
                product on time with best outcome possible.
              </p>
            </div>

            <div style={{marginTop: "50px"}}>
              <h3 className="font-weight-light">Personal Info</h3>
              <span className="line mb-5"></span>
              <ul className="mt40 info list-unstyled">
                <li>
                  <span>Email</span> : manurai.aus@gmail.com
                </li>
                <li>
                  <span>Linked In</span> :{" "}
                  <a
                      href={"https://www.linkedin.com/in/manindersinghrai/"}
                      target={"blank"}
                  >
                    Maninder
                  </a>
                </li>
                <li>
                  <span>From</span> : Victoria, Australia.
                </li>
              </ul>
            </div>

            <div style={{marginTop: "50px"}}>
              <h3 className="font-weight-light">Live Project</h3>
              <span className="line mb-5"></span>
              <ul className="mt40 info list-unstyled">
                <li>
                  <span>Url</span> : <a href="https://zarx.biz" target={"_blank"}>zarx</a>
                </li>
                <li>
                  This aplication is maintained by me so you can have a look, Incase you would like to check.
                  Tech Stack used: Nextjs in the frontend + Laravel used in the Backend.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8 about-card">
            <h3 className="font-weight-light">My Experience</h3>
            <span className="line mb-5"></span>
            <div className="row">
              <div className="col-1 text-danger pt-1">
                <i className="ti-widget icon-lg"></i>
              </div>
              <div className="col-10 ml-auto mr-3">
                <h6>Backend Development</h6>
                <p className="subtitle">
                  I have developed complex applications, Worked with large and
                  <strong> Complex databases applications </strong>. Setting up
                  queues for batch data processing using{" "}
                  <strong>RabbitMQ</strong>, Working experience in building{" "}
                  <strong>MicroServices</strong>. Worked with many Third party{" "}
                  <strong>API integrations and Api development</strong>. Hands
                  on experince with cacheing machanisms like Redis, Strong
                  knowledge of Relational and noSQL Databases such as{" "}
                  <strong>MySQL, MongoDB.</strong> I also work with{" "}
                  <strong>AWS</strong> and <strong>CICD Using Jenkins.</strong>
                </p>
                <p>
                  I also worked with Payment gatways such as{" "}
                  <strong>PayPal</strong>, <strong>Stripe</strong>,
                  <strong>WorldPay</strong>, <strong>Checkout</strong> etc.
                </p>
                <p>
                  Api's such as <strong>Zoho</strong>,{" "}
                  <strong>SalesForce</strong>, <strong>Auth0</strong>,{" "}
                  <strong>Mt4, Mt5</strong> etc
                </p>
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col-1 text-danger pt-1">
                <i className="ti-paint-bucket icon-lg"></i>
              </div>
              <div className="col-10 ml-auto mr-3">
                <h6>Frontend Development</h6>
                <p className="subtitle">
                  I Worked full stack because of that over the years i have
                  gained experience with <strong>VueJS</strong>,{" "}
                  <strong>ReactJS</strong>, <strong>TypeScript</strong> and{" "}
                  <strong>OOP JavaScript</strong>. Not just that, I have Hands
                  on experience with <strong>Redux state management</strong>{" "}
                  system and also <strong>Context APi in React</strong>. I also
                  developed applications in <strong>NextJS</strong>. For styling
                  i have working with <strong>Chakra UI </strong>and
                  <strong>Shadcn UI</strong>. Also worked with{" "}
                  <strong>Tailwind CSS</strong>.
                </p>
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col-1 text-danger pt-1">
                <i className="ti-stats-up icon-lg"></i>
              </div>
              <div className="col-10 ml-auto mr-3">
                <h6>Learning Every Day...</h6>
                <p className="subtitle">
                  I prefer to update on daily bases, I am focused towards
                  updating my knowledge every day, So i follow latest news
                  related to the stack i use. I try to make applications as
                  fast, secure and awesome as possible.
                </p>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
