import React from "react";

export default function Header() {
  return (
    <>
      <header className="header">
        <div className="container">
          <ul className="social-icons pt-3">
            {/* <li className="social-item"><a className="social-link text-light" href="#"><i className="ti-facebook" aria-hidden="true"></i></a></li>
                      <li className="social-item"><a className="social-link text-light" href="#"><i className="ti-twitter" aria-hidden="true"></i></a></li>
                      <li className="social-item"><a className="social-link text-light" href="#"><i className="ti-google" aria-hidden="true"></i></a></li>
                      <li className="social-item"><a className="social-link text-light" href="#"><i className="ti-instagram" aria-hidden="true"></i></a></li>
                      <li className="social-item"><a className="social-link text-light" href="#"><i className="ti-github" aria-hidden="true"></i></a></li> */}
          </ul>
          <div className="header-content">
            <h4 className="header-subtitle">Hi, Welcome, I am</h4>
            <h2 className="header-title">Maninder Singh</h2>

            <a
              href={"/assets/works/Resume_Maninder_SIngh.docx"}
              download={"/assets/works/Resume_Maninder_SIngh.docx"}
              style={{ paddingBottom: "5px" }}
            >
              <button className="btn btn-primary btn-rounded">
                <i className="ti-download pr-2"></i>
                Download My Resume Docx File.
              </button>
            </a>

            <a
              href={"/assets/works/Resume_Maninder_SIngh.pdf"}
              download={"/assets/works/Resume_Maninder_SIngh.pdf"}
              style={{ marginLeft: "5px", paddingBottom: "5px" }}
            >
              <button className="btn btn-primary btn-rounded">
                <i className="ti-download pr-2"></i>
                Download My Resume PDF File.
              </button>
            </a>
          </div>
        </div>
      </header>
    </>
  );
}
