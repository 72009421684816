import axios from "axios";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function processForm(e: any) {
    e.preventDefault();

    if (name !== "" && email !== "" && message !== "") {
      axios
        .post("http://localhost/api/send/email/to/manu", {
          name: name,
          email: email,
          message: message,
        })
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            toast.success(res.data, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            toast.error(res.data, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        });
    } else {
      toast.error(
        "Please make sure, Name, Email and message fields are all filled, Then send.",
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="section contact" id="contact">
        <div className="map">
          <div className="container">
            <div className="row contactArea">
              {/*<div className="col-lg-8">*/}
              {/*  <div className="contact-form-card">*/}
              {/*    <h4 className="contact-title">Send a message</h4>*/}
              {/*    <form action="">*/}
              {/*      <div className="form-group">*/}
              {/*        <input*/}
              {/*          className="form-control"*/}
              {/*          type="text"*/}
              {/*          placeholder="Name here *"*/}
              {/*          required*/}
              {/*          value={name}*/}
              {/*          onChange={(e) => setName(e.target.value)}*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*      <div className="form-group">*/}
              {/*        <input*/}
              {/*          className="form-control"*/}
              {/*          type="email"*/}
              {/*          placeholder="Email *"*/}
              {/*          required*/}
              {/*          value={email}*/}
              {/*          onChange={(e) => setEmail(e.target.value)}*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*      <div className="form-group">*/}
              {/*        <textarea*/}
              {/*          className="form-control"*/}
              {/*          id=" */}
              {/*                              placeholder="*/}
              {/*          required*/}
              {/*          value={message}*/}
              {/*          onChange={(e) => setMessage(e.target.value)}*/}
              {/*        ></textarea>*/}
              {/*      </div>*/}
              {/*      <div className="form-group ">*/}
              {/*        <button*/}
              {/*          type="submit"*/}
              {/*          className="form-control btn btn-primary"*/}
              {/*          onClick={(e) => processForm(e)}*/}
              {/*        >*/}
              {/*          Send Message*/}
              {/*        </button>*/}
              {/*      </div>*/}
              {/*    </form>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="col-lg-12">
                <div className="contact-info-card">
                  <h4 className="contact-title">Get in touch</h4>
                  {
                    <div className="row mb-2">
                      <div className="col-1 pt-1 mr-1">
                        <i className="ti-mobile icon-md"></i>
                      </div>
                      <div className="col-10 ">
                        <h6 className="d-inline">
                          Phone : <br />{" "}
                          <span className="text-muted">+ (61) 423-362-130</span>
                        </h6>
                      </div>
                    </div>
                  }
                  <div className="row mb-2">
                    <div className="col-1 pt-1 mr-1">
                      <i className="ti-map-alt icon-md"></i>
                    </div>
                    <div className="col-10">
                      <h6 className="d-inline">
                        Address :<br />{" "}
                        <span className="text-muted">Melbourne, Vic, AU</span>
                      </h6>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-1 pt-1 mr-1">
                      <i className="ti-envelope icon-md"></i>
                    </div>
                    <div className="col-10">
                      <h6 className="d-inline">
                        Email :<br />{" "}
                        <span className="text-muted">
                          manurai.aus@gmail.com
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
