import React from "react";

export default function Services() {
  return (
    <>
      <section className="section" id="service">
        <div className="container">
          <h2 className="mb-5 pb-4">
            <span className="text-danger">What</span> i do at work
          </h2>
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="card mb-5">
                <div className="card-header has-icon">
                  <i className="ti-vector text-danger" aria-hidden="true"></i>
                </div>
                <div className="card-body px-4 py-3">
                  <h5 className="mb-3 card-title text-dark">PHP</h5>
                  <p className="subtitle">
                    I have worked on Old and New code bases both. So i am
                    comfortable to work on any projects and provide solutions. I
                    follow best Practices advised by fellow developers and
                    Languages.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="card mb-5">
                <div className="card-header has-icon">
                  <i className="ti-vector text-danger" aria-hidden="true"></i>
                </div>
                <div className="card-body px-4 py-3">
                  <h5 className="mb-3 card-title text-dark">NodeJS</h5>
                  <p className="subtitle">
                    It was an opertunity to learn my Second backend language and
                    i took it. I have been working on a NodeJS project where
                    NodeJS used as API, I am using Redis, ReactJS, RabbitMq,
                    ExpressJS with NodeJS along With TypeScript.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="card mb-5">
                <div className="card-header has-icon">
                  <i className="ti-write text-danger" aria-hidden="true"></i>
                </div>
                <div className="card-body px-4 py-3">
                  <h5 className="mb-3 card-title text-dark">Testing</h5>
                  <p className="subtitle">
                    I backup my applications with Unit tests. In all Laravel
                    applications i have implemented Unit tests.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="card mb-5">
                <div className="card-header has-icon">
                  <i className="ti-package text-danger" aria-hidden="true"></i>
                </div>
                <div className="card-body px-4 py-3">
                  <h5 className="mb-3 card-title text-dark">
                    Meeting Deadlines
                  </h5>
                  <p className="subtitle">
                    I make path in my development cycle so i can meet
                    organisations goal, And deliver the product well before
                    deadline.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="card mb-5">
                <div className="card-header has-icon">
                  <i className="ti-map-alt text-danger" aria-hidden="true"></i>
                </div>
                <div className="card-body px-4 py-3">
                  <h5 className="mb-3 card-title text-dark">TypeScript</h5>
                  <p className="subtitle">
                    Another major thing that makes my code alot cleaner and less
                    prone to bugs. I use typescript on daily bases in my
                    projects.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="card mb-5">
                <div className="card-header has-icon">
                  <i
                    className="ti-bar-chart text-danger"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="card-body px-4 py-3">
                  <h5 className="mb-3 card-title text-dark">
                    Solid Principles
                  </h5>
                  <p className="subtitle">
                    I make applications with Performance, Design, and Security.
                    I use all good programming practices with documentation. .
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="card mb-5">
                <div className="card-header has-icon">
                  <i className="ti-support text-danger" aria-hidden="true"></i>
                </div>
                <div className="card-body px-4 py-3">
                  <h5 className="mb-3 card-title text-dark">
                    Helping Other Devs
                  </h5>
                  <p className="subtitle">
                    I help Junior Devs where they can make improvements. I
                    review their code and suggest them accordingly
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
