import React from 'react';
import Header from './Header.tsx'
import Developer from './Developer.tsx'
import Navigation from './Navigation.tsx'
import Resume from './Resume.tsx';
import Stats from './Stats.tsx';
import Services from './Services.tsx'
import Portfolio from './Portfolio.tsx';
import Contact from './Contact.tsx';


export default function Main() {
  return (
    <>
      <Header />
      <Navigation/>
      <Developer />
      <Resume />
      <Stats />
      <Services />
      <Portfolio />
      <Contact />
    </>
  );
}
