import React from "react";

export default function Resume() {
  return (
    <>
      <section className="section" id="resume">
        <div className="container">
          <h2 className="mb-5">
            <span className="text-danger">My</span> Resume
          </h2>
          {/*<div className="row">*/}
          {/*  <div className="col-lg-12">*/}
          {/*    <div className="card">*/}
          {/*      <div className="card-header">*/}
          {/*        <div className="pull-left">*/}
          {/*          <h3 className="mt-2">Skills / Working Knowledge</h3>*/}
          {/*          <span className="line"></span>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="card-body pb-2">*/}
          {/*        <h6>*/}
          {/*          <strong>HTML 5, CSS 3, BootStrap, TailWind Css</strong>*/}
          {/*        </h6>*/}
          {/*        <div className="progress mb-3">*/}
          {/*          <div*/}
          {/*            className="progress-bar bg-danger"*/}
          {/*            role="progressbar"*/}
          {/*            style={{ width: "95%" }}*/}
          {/*            aria-valuenow="25"*/}
          {/*            aria-valuemin="0"*/}
          {/*            aria-valuemax="100"*/}
          {/*          ></div>*/}
          {/*        </div>*/}
          {/*        <h6>*/}
          {/*          <strong>JavaScript / JQuery / Ajax / OOP JavaScript</strong>*/}
          {/*        </h6>*/}
          {/*        <div className="progress mb-3">*/}
          {/*          <div*/}
          {/*            className="progress-bar bg-danger"*/}
          {/*            role="progressbar"*/}
          {/*            style={{ width: "95%" }}*/}
          {/*            aria-valuenow="25"*/}
          {/*            aria-valuemin="0"*/}
          {/*            aria-valuemax="100"*/}
          {/*          ></div>*/}
          {/*        </div>*/}
          {/*        <h6>*/}
          {/*          <strong>VueJS / React JS / TypeScript</strong>*/}
          {/*        </h6>*/}
          {/*        <div className="progress mb-3">*/}
          {/*          <div*/}
          {/*            className="progress-bar bg-danger"*/}
          {/*            role="progressbar"*/}
          {/*            style={{ width: "90%" }}*/}
          {/*            aria-valuenow="50"*/}
          {/*            aria-valuemin="0"*/}
          {/*            aria-valuemax="100"*/}
          {/*          ></div>*/}
          {/*        </div>*/}
          {/*        <h6>*/}
          {/*          <strong>NodeJS / Express JS </strong>*/}
          {/*        </h6>*/}
          {/*        <div className="progress mb-3">*/}
          {/*          <div*/}
          {/*            className="progress-bar bg-danger"*/}
          {/*            role="progressbar"*/}
          {/*            style={{ width: "80%" }}*/}
          {/*            aria-valuenow="75"*/}
          {/*            aria-valuemin="0"*/}
          {/*            aria-valuemax="100"*/}
          {/*          ></div>*/}
          {/*        </div>*/}
          {/*        <h6>*/}
          {/*          <strong>MySQL / MongoDB / Redis</strong>*/}
          {/*        </h6>*/}
          {/*        <div className="progress mb-3">*/}
          {/*          <div*/}
          {/*            className="progress-bar bg-danger"*/}
          {/*            role="progressbar"*/}
          {/*            style={{ width: "95%" }}*/}
          {/*            aria-valuenow="75"*/}
          {/*            aria-valuemin="0"*/}
          {/*            aria-valuemax="100"*/}
          {/*          ></div>*/}
          {/*        </div>*/}
          {/*        <h6>*/}
          {/*          <strong>PHP ( Laravel - CodeIgniter - CakePHP )</strong>*/}
          {/*        </h6>*/}
          {/*        <div className="progress mb-3">*/}
          {/*          <div*/}
          {/*            className="progress-bar bg-danger"*/}
          {/*            role="progressbar"*/}
          {/*            style={{ width: "97%" }}*/}
          {/*            aria-valuenow="75"*/}
          {/*            aria-valuemin="0"*/}
          {/*            aria-valuemax="100"*/}
          {/*          ></div>*/}
          {/*        </div>*/}
          {/*        <h6>*/}
          {/*          <strong>Docker / AWS / Servers / Linux</strong>*/}
          {/*        </h6>*/}
          {/*        <div className="progress mb-3">*/}
          {/*          <div*/}
          {/*            className="progress-bar bg-danger"*/}
          {/*            role="progressbar"*/}
          {/*            style={{ width: "90%" }}*/}
          {/*            aria-valuenow="75"*/}
          {/*            aria-valuemin="0"*/}
          {/*            aria-valuemax="100"*/}
          {/*          ></div>*/}
          {/*        </div>*/}
          {/*        <h6>*/}
          {/*          <strong>*/}
          {/*            API's - WebHooks (Integaration & Development ),*/}
          {/*            Microservices*/}
          {/*          </strong>*/}
          {/*        </h6>*/}
          {/*        <div className="progress mb-3">*/}
          {/*          <div*/}
          {/*            className="progress-bar bg-danger"*/}
          {/*            role="progressbar"*/}
          {/*            style={{ width: "95%" }}*/}
          {/*            aria-valuenow="75"*/}
          {/*            aria-valuemin="0"*/}
          {/*            aria-valuemax="100"*/}
          {/*          ></div>*/}
          {/*        </div>*/}
          {/*        <h6>*/}
          {/*          <strong>Git Version Control / CI-CD / Jenkins</strong>*/}
          {/*        </h6>*/}
          {/*        <div className="progress mb-3">*/}
          {/*          <div*/}
          {/*            className="progress-bar bg-danger"*/}
          {/*            role="progressbar"*/}
          {/*            style={{ width: "90%" }}*/}
          {/*            aria-valuenow="75"*/}
          {/*            aria-valuemin="0"*/}
          {/*            aria-valuemax="100"*/}
          {/*          ></div>*/}
          {/*        </div>*/}

          {/*        <h6>*/}
          {/*          <strong>WordPress</strong>*/}
          {/*        </h6>*/}
          {/*        <div className="progress mb-3">*/}
          {/*          <div*/}
          {/*            className="progress-bar bg-danger"*/}
          {/*            role="progressbar"*/}
          {/*            style={{ width: "90%" }}*/}
          {/*            aria-valuenow="75"*/}
          {/*            aria-valuemin="0"*/}
          {/*            aria-valuemax="100"*/}
          {/*          ></div>*/}
          {/*        </div>*/}

          {/*        <h6>*/}
          {/*          <strong>*/}
          {/*            Laravel - Feature & Unit Based Testing (TDD)*/}
          {/*          </strong>*/}
          {/*        </h6>*/}
          {/*        <div className="progress mb-3">*/}
          {/*          <div*/}
          {/*            className="progress-bar bg-danger"*/}
          {/*            role="progressbar"*/}
          {/*            style={{ width: "90%" }}*/}
          {/*            aria-valuenow="75"*/}
          {/*            aria-valuemin="0"*/}
          {/*            aria-valuemax="100"*/}
          {/*          ></div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className="card">*/}
          {/*      <div className="card-header">*/}
          {/*        <div className="pull-left">*/}
          {/*          <h4 className="mt-2">Languages</h4>*/}
          {/*          <span className="line"></span>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="card-body pb-2">*/}
          {/*        <h6>English</h6>*/}
          {/*        <div className="progress mb-3">*/}
          {/*          <div*/}
          {/*            className="progress-bar bg-danger"*/}
          {/*            role="progressbar"*/}
          {/*            style={{ width: "90%" }}*/}
          {/*            aria-valuenow="25"*/}
          {/*            aria-valuemin="0"*/}
          {/*            aria-valuemax="100"*/}
          {/*          ></div>*/}
          {/*        </div>*/}
          {/*        <h6>Hindi</h6>*/}
          {/*        <div className="progress mb-3">*/}
          {/*          <div*/}
          {/*            className="progress-bar bg-danger"*/}
          {/*            role="progressbar"*/}
          {/*            style={{ width: "99%" }}*/}
          {/*            aria-valuenow="25"*/}
          {/*            aria-valuemin="0"*/}
          {/*            aria-valuemax="100"*/}
          {/*          ></div>*/}
          {/*        </div>*/}
          {/*        <h6>Punjabi</h6>*/}
          {/*        <div className="progress mb-3">*/}
          {/*          <div*/}
          {/*            className="progress-bar bg-danger"*/}
          {/*            role="progressbar"*/}
          {/*            style={{ width: "90%" }}*/}
          {/*            aria-valuenow="50"*/}
          {/*            aria-valuemin="0"*/}
          {/*            aria-valuemax="100"*/}
          {/*          ></div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="row">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-header">
                  <div className="mt-2">
                    <h3>Experience</h3>
                    <span className="line"></span>
                  </div>
                </div>
                <div className="card-body">
                  <h6 className="title text-danger">March 2022 - Present</h6>
                  <p>
                    COMPANY / ORGANIZATION:{" "}
                    <a
                      href={"https://gomarkets.com"}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      GoMarkets
                    </a>
                  </p>
                  <p className="subtitle">
                    WORKED ON VARIETY OF <strong>PHP</strong> BASED PROJECTS.{" "}
                    <strong>Laravel</strong> CUSTOMIZATION. In Developement of
                    multiple Applications API's and WebHooks{" "}
                    <strong>
                      Dealing with Huge Amount Of Data, For Performance and
                      Reportings. Writing Secure code.
                    </strong>
                    Also Worked with an Project where <strong>NodeJS</strong>{" "}
                    used for Backend. Not just this Working With ReactJS based
                    frontend in applications also With colobrating, Helping with
                    team of other developers.
                  </p>
                  <hr />
                  <h6 className="title text-danger">
                    AUGUST 2019 - March 2022
                  </h6>
                  <p>
                    COMPANY / ORGANIZATION:{" "}
                    <a
                      href="https://www.soupedup.com/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      SoupedUp Solutions
                    </a>
                  </p>
                  <p className="subtitle">
                    CUSTOMIZED APPLICATION USING TECHNOLOGIES SUCH AS VUEJS,
                    REACTJS, TYPESCRIPT AND LARAVEL. Api Development, Worked
                    with various Third party Api's and integraded in Companies
                    Product. Worked with webhooks.
                  </p>
                  <hr />
                  <h6 className="title text-danger">
                    MARCH 2018 - NOVEMBER 2018
                  </h6>
                  <p>
                    COMPANY / ORGANIZATION:{" "}
                    <a
                      href="https://technodeviser.com/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Techno Deviser
                    </a>
                  </p>
                  <p>
                    WORKED ON VARIETY OF WORDPRESS AND PHP BASED PROJECTS,
                    <strong> PHP API DEVELOPMENT </strong>.
                  </p>
                  <p className="subtitle">.</p>
                  <hr />
                  <h6 className="title text-danger">
                    SEPTEMBER 2015 - FEBRUARY 2018
                  </h6>
                  <p>COMPANY / ORGANIZATION: INFRA TECH WEB</p>
                  <p className="subtitle">
                    WORKED ON VARIETY OF <strong>PHP</strong> BASED PROJECTS.{" "}
                    <strong>WordPress</strong> CUSTOMIZATION. WORKED WITH
                    TECHNOLOGIES SUCH AS{" "}
                    <strong>PHP, JQUERY, HTML, CSS, BOOTSTRAP, AJAX</strong>
                    ETC. DESIGNING RESPONSIVE TEMPLATES SETTING UP VALIDATIONS
                    AND CONNECTING THE APPLICATION TO DATABASE USING PHP
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card">
                <div className="card-header">
                  <div className="mt-2">
                    <h3>Education</h3>
                    <span className="line"></span>
                  </div>
                </div>
                <div className="card-body">
                  <h6 className="title text-danger">
                    AWS Cloud Practitioner Certification{" May 2024 "}
                  </h6>
                  <p>
                    From:{" AMAZON WEB SERVICES"}
                    <br />
                    <a
                        href={"https://www.credly.com/badges/41dc253d-c949-492d-a776-027dbd80e817/public_url"}
                        target={"_blank"}
                        rel="noreferrer"
                    >
                      View Certification
                    </a>
                  </p>
                  <p className="subtitle">
                    Completed in <strong> First Divition</strong>.
                  </p>

                  <h6 className="title text-danger">
                    BACHELOR OF COMPUTER APPLICATIONS{" "}
                  </h6>
                  <p>
                    UNIVERSITY:{" "}
                    <a
                        href={"https://www.amity.edu/"}
                        target={"_blank"}
                        rel="noreferrer"
                    >
                      Amity University
                    </a>
                  </p>
                  <p className="subtitle">
                    Completed in <strong> First Divition</strong>.
                  </p>

                  <hr/>
                  <h6 className="title text-danger">
                    DIPLOMA IN COMPUTER APPLICATION <br/>
                  </h6>

                  <p>
                    UNIVERSITY:{" "}
                    <a
                        href={"https://www.lpu.in/"}
                        target={"_blank"}
                        rel="noreferrer"
                    >
                      LOVELY PROFESSIONAL UNIVERSITY
                    </a>
                    <br/>
                    <small>( 2016 -2017 )</small>
                  </p>
                  <p className="subtitle">
                    Completed My Diploma from Lovely Professional University.
                    Got all the required skills for software development.
                  </p>

                  <hr/>

                  <h6 className="title text-danger">CLASS 12TH</h6>
                  <p>
                    UNIVERSITY / INSTITUTE :{" "}
                    <a
                        href={"https://bseh.org.in/"}
                        target={"_blank"}
                        rel="noreferrer"
                    >
                      Board Of School Education Haryana
                    </a>
                  </p>
                  <p className="subtitle">
                    Completed 12th from Board Of School Education Haryana
                    (India). Got all the required skills for better future.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
