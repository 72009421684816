export const Works = [
  {
    title: "Task manager Application VueJs And Laravel",
    url: "https://i.imgur.com/VN2os5y.gif",
    class: "laravel all",
    category: "Laravel & VueJS",
  },
  {
    title: "Laravel Queues and Batching (Import With Progressbar)",
    url: "https://i.imgur.com/v3HHy28.gif",
    class: "laravel all",
    category: "Laravel",
  },
  {
    title: "Professional Url Shortener App",
    url: "https://i.imgur.com/OrHFWMX.gif",
    class: "laravel all",
    category: "Laravel",
  },
  {
    title: "Crud made using React.js and Laravel 8",
    url: "https://i.imgur.com/1D2rrHM.gif",
    class: "react all laravel",
    category: "Laravel & ReactJS",
  },
  {
    title: "PDF Viewer For Website Using Javascript OOP + Laravel 8",
    url: "https://i.imgur.com/AaLQfuW.gif",
    class: "laravel all",
    category: "Laravel & Javascript",
  },
  {
    title: "Note Manager App (OOP Javascript)",
    url: "https://i.imgur.com/BKzuW6g.gif",
    class: "github all",
    category: "JavaScript",
  },
  {
    title: "Laravel Queues and Batching (Import With Progressbar)",
    url: "https://i.imgur.com/v3HHy28.gif",
    class: "laravel all",
    category: "Laravel",
  },
  {
    title: "WordPress Plugin Developed In PHP OOP",
    url: "assets/imgs/3.jpg",
    class: "wordpress all",
    category: "WordPress",
  },
  {
    title: "WordPress Plugin Unzipper Media to Media Library",
    url: "https://i.imgur.com/7Orthas.gif",
    class: "wordpress all",
    category: "WordPress",
  },
  {
    title: "WordPress CMS Based Custom Theme",
    url: "assets/imgs/2.jpg",
    class: "wordpress all",
    category: "WordPress",
  },
];
   