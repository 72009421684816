import React, { useEffect, useRef, useState } from "react";
import { Works as WorksData } from "./../Works";

export default function Portfolio() {
  const [works, setWorks] = useState<any>();
  const loadPortfolio = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setWorks(WorksData);
    }, 2000);
  }, [works]);

  return (
    <>
      <section className="section bg-custom-gray" id="portfolio">
        <div className="container">
          <h1 className="mb-5">
            <span className="text-danger">My Applications</span> Big and Small
          </h1>
          <hr/>
          <strong>( This Section Last Updated: Nov 2021 ), You can check my recent Work.
            <a href={"https://zarx.biz"} target={"_blank"}>
              {" "}  A live Project Here
            </a>
          </strong>
          <hr/>
          <div className="portfolio">
            <div className="filters">
              <a
                  href="/#"
                  data-filter=".all"
                  className="active"
                  ref={loadPortfolio}
              >
                All
              </a>
              <a href="/#" data-filter=".wordpress">
                WordPress
              </a>
              <a href="/#" data-filter=".laravel">
                Laravel
              </a>
              <a href="/#" data-filter=".react">
                React
              </a>
              <a href="/#" data-filter=".github">
                Github Repos
              </a>
            </div>
            <div className="portfolio-container row">
              {works &&
                  works.map(
                      (
                          item: {
                            class: string;
                            url: string | undefined;
                            title:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<
                                any,
                                string | React.JSXElementConstructor<any>
                            >
                                | React.ReactFragment
                                | null
                                | undefined;
                            category:
                                | string
                                | number
                                | boolean
                                | React.ReactElement<
                                any,
                                string | React.JSXElementConstructor<any>
                            >
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined;
                          },
                          index: React.Key | null | undefined
                      ) => {
                        return (
                            <div
                                className={"col-md-6 col-lg-4 web " + item.class}
                                key={index}
                            >
                              <div className="portfolio-item">
                                <img
                                    src={item.url}
                                    className="img-fluid"
                                    alt={item.title}
                                />
                                <div className="content-holder">
                                  <div className="text-holder">
                                    <h6 className="title">{item.title}</h6>
                                    <strong>
                                      <p className="subtitle">{item.category}</p>
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                        );
                      }
                  )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
