import './App.css';
import Main from './components/Main.tsx';
function App() {
  return (
    <>
      <Main/>
    </>
  );
}

export default App;
